import React, { useEffect } from "react";
import axios from "axios";
import { useLazyQuery } from "@apollo/client";
import { GET_DETAILS } from "./gql";

export default function PaymentForm() {
  const redirectUrl = `${process.env.REACT_APP_BACKEND_URL}/checkout`;

  const [loadProducts, { data }] = useLazyQuery(GET_DETAILS);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get("id");

    if (id) {
      loadProducts({
        variables: {
          id,
        },
      });
    }
    home_page_viewed();
  }, []);

  useEffect(() => {
    if (data) {
      let total = data?.cart?.prices?.grand_total || {};
      if (total.value !== 0) {
        createCheckout(total);
      }
    }
  }, [data]);

  const createCheckout = async (total) => {
    let amount = total.value;
    let currency = "SAR";

    amount = amount.toFixed(2);

    try {
      const response = await axios.post(
        "https://test.oppwa.com/v1/checkouts",
        new URLSearchParams({
          entityId: "8ac7a4c8932dc70301933e4e534d0fd6",
          amount,
          currency,
          paymentType: "PA",
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization:
              "Bearer OGFjN2E0Yzg5MzJkYzcwMzAxOTMzZTRkMzM3OTBmZDJ8RlVBaHVtOiUrQTM1R0xmV1FEWnQ=",
          },
        }
      );

      const checkoutId = response.data.id;

      // Set wpwlOptions globally
      window.wpwlOptions = {
        brandDetection: true,
        onReady: function () {
          // Customize widget behavior
          const visa = document
            .querySelector(".wpwl-brand:first-child")
            ?.cloneNode(true);
          if (visa) {
            visa.classList.add("wpwl-brand-custom", "wpwl-brand-VISA");
            const master = visa.cloneNode(true);
            master.classList.replace("wpwl-brand-VISA", "wpwl-brand-MASTER");
            document.querySelector(".wpwl-brand:first-child")?.after(master);
            visa.style.backgroundImage =
              "url(https://eu-test.oppwa.com/v1/static/" +
              window.wpwl.cacheVersion +
              "/img/brand.png)";
          }
        },
      };

      // Dynamically load the payment widget script
      const scriptId = "payment-widget";
      const existingScript = document.getElementById(scriptId);

      if (!existingScript) {
        const script = document.createElement("script");
        script.src = `https://test.oppwa.com/v1/paymentWidgets.js?checkoutId=${checkoutId}`;
        script.id = scriptId;
        script.crossOrigin = "anonymous";
        script.async = true;
        document.head.appendChild(script);
      }
    } catch (error) {
      console.error("Error creating checkout:", error);
    }
  };

  const home_page_viewed = () => {
    axios
      .post(
        "https://api.ksa.webengage.com/v1/accounts/ksa~~15ba20520/events",
        {
          userId: "12", // Ensure this is a string
          eventName: "home_page_viewed",
          eventData: {
            event_source: "Web",
          },
        },
        {
          headers: {
            Authorization: `Bearer a0ed4136-17c9-42c1-a6f4-a023157a5920`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log("Success:", res.data);
      })
      .catch((err) => {
        if (err.response) {
          console.error("Error Response:", err.response.data);
          console.error("Status:", err.response.status);
        } else if (err.request) {
          console.error("No Response:", err.request);
        } else {
          console.error("Error:", err.message);
        }
      });
  };

  return (
    <div>
      <form
        action={redirectUrl}
        className="paymentWidgets"
        data-brands="VISA MASTER AMEX MADA"
      />
    </div>
  );
}
