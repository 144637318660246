import { gql } from "@apollo/client";

export const GET_DETAILS = gql`
  query getDetails($id: String!) {
    cart(cart_id: $id) {
      shipping_addresses {
        selected_shipping_method {
          amount {
            currency
            value
          }
        }
        street
      }
      prices {
        grand_total {
          currency
          value
        }
      }
    }
  }
`;
