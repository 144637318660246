import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import PaymentForm from "./components/PaymentForm";
import client from "./ApolloClient";
import { ApolloProvider } from "@apollo/client";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ApolloProvider client={client}>
    <PaymentForm />
  </ApolloProvider>
);
