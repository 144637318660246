import { ApolloClient, InMemoryCache } from "@apollo/client";

const client = new ApolloClient({
  uri: `${process.env.REACT_APP_BACKEND_URL}/graphql`,
  cache: new InMemoryCache({
    typePolicies: {
      Product: {
        keyFields: ["id"], // Adjust if your identifier is different
      },
      SimpleProduct: {
        keyFields: ["id"], // Ensure this matches the product identifier in your GraphQL schema
      },
    },
  }),
});

export default client;
